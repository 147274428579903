import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { setBodyBackground } from "../utils/colorUtils";

const LayoutCensus = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    setBodyBackground("light");
  }, []);

  return (
    <React.Fragment>
      <body className="font-poppins">
        <Header theme={"white"} />
        <main>{children}</main>
        <Footer theme={"white"} />
      </body>
    </React.Fragment>
  );
};

export default LayoutCensus;
