import React, { useEffect, useState } from "react";

import Distribution from "../components/sections/CaseDetails/Distribution";
import GenderRatio from "../components/sections/CaseDetails/GenderRatio";
import SalaryBand from "../components/sections/CaseDetails/SalaryBand";
import Tenure from "../components/sections/CaseDetails/Tenure";
import AgeBand from "../components/sections/CaseDetails/AgeBand";

import { fetchCaseDetails } from "../services";
import { Option, ReportData } from "../types";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { IoChevronDownOutline } from "react-icons/io5";
import { FaFileExport, FaSpinner } from "react-icons/fa";
import { useParams } from "react-router-dom";

const CaseDetails = () => {
  const { id: caseId } = useParams();
  const [selectedFile, setSelectedFile] = useState<Option | undefined>();
  const [censusData, setCensusData] = useState<ReportData["census"]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchCaseDetails(caseId);
        const data = response.data.data;
        setCensusData(data);
        setSelectedFile({
          label: decodeURIComponent(
            data.files[0].charAt(0).toUpperCase() + data.files[0].slice(1),
          ),
          value: data.files[0],
        });
        setError(undefined);
      } catch (error) {
        console.error(error);
        setError("Failed to get case details!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const fileList = censusData?.files
    ? censusData?.files.map((file) => {
        return {
          label: decodeURIComponent(
            file.charAt(0).toUpperCase() + file.slice(1),
          ),
          value: file,
        };
      })
    : [];

  const exportFile = async () => {
    try {
      setDownloading(true);
      const response = await fetchCaseDetails(
        caseId,
        "file",
        selectedFile?.value,
      );
      const fileLink = response.data.data.fileURL;
      const link = document.createElement("a");
      link.href = fileLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alert("Failed to export!");
    } finally {
      setDownloading(false);
    }
  };

  const handleFileChange = async (file: Option) => {
    try {
      setLoading(true);
      const response = await fetchCaseDetails(caseId);
      const data = response.data.data;
      setCensusData(data);
      setSelectedFile(file);
      setError(undefined);
    } catch (error) {
      console.error(error);
      setError("Failed to get case details!");
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-gray-600 p-20">
        Something went wrong!
      </div>
    );
  }

  return (
    <div className="mx-6 md:mx-14 mb-24">
      {censusData && (
        <div className="flex flex-col md:flex-row gap-2 md:justify-end md:items-center">
          <span>Processed Files:</span>
          <div className="flex gap-2">
            {selectedFile && (
              <Popover className="relative !ring-0">
                <PopoverButton className="flex items-center justify-between focus:outline-none text-sm gap-2 min-w-44 bg-white border-2 rounded-md px-4 py-2">
                  {selectedFile.label}
                  <IoChevronDownOutline />
                </PopoverButton>
                <Transition
                  enter="transition duration-75 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <PopoverPanel
                    anchor="bottom"
                    className="flex flex-col bg-white rounded-lg border z-20 mt-1"
                  >
                    <div className="w-44 bg-white rounded-lg z-20">
                      {fileList.map((item, index) => (
                        <PopoverButton
                          key={index}
                          className="block w-full text-sm text-neutral-90 hover:bg-blue-200 py-2"
                          onClick={() => handleFileChange(item)}
                        >
                          <div className="text-left hover:bg-neutral-20 transition-colors px-4 py-1.5">
                            {item.label}
                          </div>
                        </PopoverButton>
                      ))}
                    </div>
                  </PopoverPanel>
                </Transition>
              </Popover>
            )}
            <button
              className="text-white border-2 rounded-md px-4 py-2 flex items-center gap-2 bg-custom-blue-census"
              onClick={exportFile}
            >
              <span className="text-sm font-semibold">Export</span>
              {downloading ? (
                <FaSpinner className="animate-spin" />
              ) : (
                <FaFileExport />
              )}
            </button>
          </div>
        </div>
      )}
      <div
        aria-label="census body"
        className="md:grid md:grid-rows-2 md:gap-2 text-sm my-3"
      >
        <div className="md:grid md:grid-cols-3 md:gap-2 space-y-2 md:space-y-0">
          <div className="flex flex-col gap-2">
            {!loading ? (
              <>
                {censusData && (
                  <div className="flex flex-col p-5 items-center justify-center border-2 bg-white rounded-md">
                    <span className="text-lg font-medium">Total lives</span>
                    <span className="font-semibold text-2xl mt-1">
                      {censusData.totalLives}
                    </span>
                    {censusData?.livesAdditionalInfo &&
                      Number(censusData?.livesAdditionalInfo.retired) > 0 &&
                      Number(censusData?.livesAdditionalInfo.excluded) > 0 && (
                        <div className="text-neutral-60 italic text-xs space-x-1">
                          *{" "}
                          <span>
                            {censusData?.livesAdditionalInfo?.excluded
                              ? `${censusData?.livesAdditionalInfo?.excluded} excluded ${
                                  censusData?.livesAdditionalInfo?.retired
                                    ? ";"
                                    : ""
                                }`
                              : ""}
                          </span>
                          <span>
                            {censusData?.livesAdditionalInfo?.retired
                              ? `${censusData?.livesAdditionalInfo?.retired} retired`
                              : ""}
                          </span>
                        </div>
                      )}
                  </div>
                )}
              </>
            ) : (
              <div className="flex flex-col gap-2 border-2 rounded-md p-5">
                <div className="animate-pulse rounded-md bg-gray-200 w-32 h-5" />
                <div className="animate-pulse rounded-md bg-gray-200 w-44 h-5" />
              </div>
            )}
            {!loading ? (
              <>
                {censusData && (
                  <div className="flex-grow flex flex-col shadow gap-4 p-3 md:p-6 border-2 bg-white rounded-md">
                    <span className="text-lg font-medium pl-2 md:pl-0">
                      Male to Female
                    </span>
                    <GenderRatio data={censusData?.genderRatio} />
                  </div>
                )}
              </>
            ) : (
              <div className="flex-grow flex flex-col gap-2 border-2 rounded-md p-6">
                <div className="animate-pulse rounded-md bg-gray-200 w-32 h-8" />
                <div className="animate-pulse rounded-md bg-gray-200 w-full h-44" />
              </div>
            )}
          </div>
          {!loading ? (
            <>
              {censusData && (
                <div className="flex flex-col justify-between gap-2 shadow border-2 bg-white rounded-md p-5">
                  <span className="text-lg font-medium">Age band</span>
                  <AgeBand data={censusData.ageBand} />
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col justify-between gap-2 border-2 bg-white rounded-md p-5">
              <div className="animate-pulse rounded-md bg-gray-200 w-32 h-8" />
              <div className=" flex-grow animate-pulse rounded-md bg-gray-200 w-full h-44" />
            </div>
          )}

          {!loading ? (
            <>
              {censusData && (
                <div className="flex flex-col justify-between gap-2 shadow border-2 bg-white rounded-md p-5">
                  <div className="flex justify-between">
                    <span className="text-lg font-medium">Salary band</span>
                    <p className="text-slate-400">({`$'000`})</p>
                  </div>
                  <SalaryBand data={censusData.salaryBand} />
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col justify-between gap-2 border-2 bg-white rounded-md p-5">
              <div className="animate-pulse rounded-md bg-gray-200 w-32 h-8" />
              <div className=" flex-grow animate-pulse rounded-md bg-gray-200 w-full h-44" />
            </div>
          )}
        </div>
        <div className="md:grid md:grid-cols-10 md:gap-2 space-y-2 md:space-y-0 mt-2 md:mt-0">
          {!loading ? (
            <>
              {censusData && (
                <div className="md:col-span-6 flex flex-col gap-4 border-2 shadow bg-white rounded-md py-4 px-6">
                  <span className="text-lg font-medium">Tenure</span>
                  <Tenure data={censusData.tenure} />
                </div>
              )}
            </>
          ) : (
            <div className="md:col-span-6 flex flex-col gap-2 border-2 bg-white rounded-md py-5 px-6">
              <div className="animate-pulse rounded-md bg-gray-200 w-32 h-8" />
              <div className=" flex-grow animate-pulse rounded-md bg-gray-200 w-full h-full" />
            </div>
          )}

          {!loading ? (
            <>
              {censusData && (
                <div className="md:col-span-4 border-2 bg-white shadow rounded-md md:gap-5 p-5">
                  <span className="text-lg font-medium">Distribution</span>
                  <Distribution data={censusData.demographics} />
                </div>
              )}
            </>
          ) : (
            <div className="md:col-span-4 flex flex-col gap-2 border-2 bg-white rounded-md py-5 px-6">
              <div className="animate-pulse rounded-md bg-gray-200 w-32 h-8" />
              <div className="flex-grow animate-pulse rounded-md bg-gray-200 w-full h-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseDetails;
