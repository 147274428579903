import React from "react";
import Surya from "./../../assets/images/surya_transparent.svg";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";
import { Separator } from "../layout/Footer";

const OurFounderStory = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 });

  return (
    <section className="bg-custom-dark">
      <div
        className={`transition-custom duration-custom in-expo ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <Separator />
      </div>
      <div
        className={`transition-custom duration-custom in-expo pb-10 md:pb-40 ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
        ref={ref}
      >
        <div className="pt-8 md:pt-24">
          <div className="flex flex-col items-center mx-auto w-4/5">
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-black text-white font-poppins text-center my-8">
              {`Our Founder's `}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD]">
                Story
              </span>
            </h1>
            <div className="flex flex-col lg:flex-row lg:gap-20 min-h-large items-center pt-8">
              <div className="text-center">
                <div className="relative w-[250px] md:w-[410px] bg-[#A7A7A72B] border border-white/70 rounded-2xl">
                  <div className="h-[276px] w-[276px] absolute opacity-25 blur-[100px] bg-[#4B7DFF] right-[230px] top-0 -z-10"></div>
                  <div className="hidden md:block h-[276px] w-[276px] absolute opacity-25 blur-[100px] bg-[#D04BFF] -right-1/2 -bottom-3/4 -z-10"></div>
                  <img
                    src={Surya}
                    alt="Founder"
                    className="block w-full"
                    width="100%"
                  />
                </div>
                <h4 className="text-lg md:text-2xl font-700 font-poppins text-custom-white-light mt-3">
                  CEO, Founder
                </h4>
                <p className="text-sm md:text-xl lg:text-2xl font-poppins font-500 text-custom-white-light leading-8">
                  Surya Jakhotia
                </p>
              </div>
              <p className="text-lg leading-custom text-gray-300 text-center md:text-left font-poppins font-400 w-4/5 pt-10 lg:pt-0">
                <span className="pb-8 md:pb-14 inline-block leading-9">
                  Surya Jakhotia, a seasoned entrepreneur and software
                  engineering expert, founded Uniblox with the vision of making
                  insurance as accessible and intuitive as e-commerce, driven by
                  modern technology and customer-centricity.
                </span>
                <span className="pb-8 md:pb-14 inline-block leading-9">
                  Prior to Uniblox, Surya led successful transformations in the
                  insurance sector, earning recognition with prestigious awards
                  for technology innovation.
                </span>
                <span className="pb-8 inline-block leading-9">
                  Dedicated to leveraging technology to solve complex business
                  challenges, he remains committed to creating solutions that
                  deliver value to both businesses and customers.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurFounderStory;
