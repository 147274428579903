import { useEffect, useState } from "react";

const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
    }
  };

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (copied) {
      intervalId = setInterval(() => {
        setCopied((copied) => !copied);
      }, 3000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [copied]);
  return { copied, handleCopyClick };
};

export default useCopyToClipboard;
