export enum fileStatus {
  UPLOADING = "uploading",
  CLASSIFIED = "classified",
  PREPROCESSING = "preprocessing",
  DEDUPLICATING = "deduplicating",
  POSTPROCESSING = "postprocessing",
  COMPLETED = "completed",
  ERROR = "error",
  FAILURE = "failure",
  SUCCESS = "success",
}
