import React from "react";
import Integrations from "../elements/Integrations";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll"; // Import the hook

const IntegrationsMain = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 }); // Initialize the hook

  return (
    <>
      <section className="text-center" ref={ref}>
        {" "}
        {/* Attach the ref to the parent section */}
        <div
          className={`w-fit m-auto font-600 text-gray-100 text-xs md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out ${revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"}`}
        >
          ✨ Integrations
        </div>
        <h2
          className={`text-4xl md:text-6xl lg:text-7xl py-8 w-4/5 mx-auto font-poppins text-custom-white border-t-gray-700 font-semibold tracking-wide antialiased transition-custom duration-custom ease-in-out ${revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"}`}
        >
          Native{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD]">
            integrations hub
          </span>
        </h2>
        <p
          className={`mb-[5rem] text-custom-blue w-4/5 m-auto text-center pb-6 font-poppins font-400 scale-x-100 tracking-wide antialiased text-sm md:text-xl max-w-80 md:max-w-2xl transition-custom duration-custom ease-in-out ${revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"}`}
        >
          Streamline operations with Uniblox’s built-in integrations that
          seamlessly connect and automate data transactions.
        </p>
      </section>
      <section className="mb-[20rem] pt-16">
        <Integrations />
      </section>
    </>
  );
};

export default IntegrationsMain;
