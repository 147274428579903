import React, { FC, useEffect, useState, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import {
  ChoroplethController,
  ProjectionScale,
  ColorScale,
  GeoFeature,
} from "chartjs-chart-geo";
import { ChartData } from "chart.js";
import { RemoveNull, ReportData } from "../../../../types";
import { STATES } from "../../../../utils/states";
import { hexToRgb, pageColors, rgbToHex } from "../../../../utils/colorUtils";

ChartJS.register(
  ...registerables,
  ChoroplethController,
  ProjectionScale,
  ColorScale,
  GeoFeature,
);

const colorInterpolate = (value: number) => {
  const rgbA = hexToRgb(pageColors["300"]);
  const rgbB = hexToRgb(pageColors["primary"]);
  const colorVal = (prop: "r" | "g" | "b") =>
    Math.round(rgbA[prop] * (1 - value) + rgbB[prop] * value);
  return rgbToHex(colorVal("r"), colorVal("g"), colorVal("b"));
};

interface DataItem {
  state: string;
  count: number;
}
const Distribution: FC<{
  data: RemoveNull<ReportData["census"]>["demographics"];
}> = ({ data }) => {
  const distributionData = useMemo(() => {
    return STATES.reduce<DataItem[]>((acc, state) => {
      if (data && data[state.value]) {
        acc.push({ state: state.label, count: data[state.value] });
      }
      return acc;
    }, []);
  }, [data]);
  const [chartData, setChartData] = useState<ChartData>();
  const [scaleData, setScaleData] = useState({
    min: 0,
    max: 0,
  });
  useEffect(() => {
    fetch("https://unpkg.com/us-atlas/states-10m.json")
      .then((r) => r.json())
      .then((us) => {
        const nation = (ChartGeo.topojson.feature(us, us.objects.nation) as any)
          .features[0];
        const states = (ChartGeo.topojson.feature(us, us.objects.states) as any)
          .features;

        const statesData: { feature: ChartGeo.Feature; value: number }[] =
          states.map((d: ChartGeo.Feature) => {
            const state = STATES.find((item) => {
              return item.label == d.properties.name;
            })?.label;
            const result = distributionData.find((item) => {
              return item.state == state;
            });
            const scale = result?.count ?? 0;

            return {
              feature: d,
              value: scale,
            };
          });

        setChartData({
          labels: states.map((d: ChartGeo.Feature) => d.properties.name),
          datasets: [
            {
              label: "States",
              outline: nation,
              data: statesData,
            },
          ],
        });
        setScaleData({
          min: Math.min(...statesData.map((item) => item.value)),
          max: Math.max(...statesData.map((item) => item.value)),
        });
      });
  }, [distributionData]);

  return (
    <div className="flex flex-col bg-white sm:pt-5">
      <div className="min-h-0 flex flex-col rounded mt-2 justify-between gap-4 flex-grow">
        {chartData && (
          <>
            <Chart
              height={"34%"}
              width={"100%"}
              type="choropleth"
              data={chartData}
              options={{
                backgroundColor(ctx) {
                  const value = (ctx.raw as { value: number })?.value;
                  const percentage = value / scaleData.max;
                  return scaleData.max === 0 ||
                    (ctx.raw as { value: number })?.value === 0
                    ? "#e7e7e7"
                    : colorInterpolate(Math.round(percentage));
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  color: {
                    axis: "x",
                    quantize: 5,
                    display: false,
                  },
                },
              }}
            />
            <div className="flex flex-col justify-center px-4">
              <div className="h-4 w-full bg-gradient-to-r from-[#DBE9FE]  via-[#C1DCFE] to-[#3C82F6] " />

              <div className="flex justify-between mt-2">
                <div className="font-normal text-neutral-90 text-xs">
                  <div>Low</div>
                  <div>{scaleData.min}</div>
                </div>
                <div className="font-normal text-neutral-90 text-xs">
                  <div>High</div>
                  <div>{scaleData.max}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Distribution;
