import React from "react";
import MissionandVision from "../components/sections/MissionandVision";
import WhatWeBelieve from "../components/sections/WhatWeBelieve";
import OurFounderStory from "../components/sections/OurFounderStory";
import MeetOurTeam from "../components/sections/MeetOurTeam";
import AboutusHero from "../components/sections/AboutusHero";

function Aboutus() {
  return (
    <>
      <AboutusHero />
      <MissionandVision />
      <WhatWeBelieve />
      <MeetOurTeam />
      <OurFounderStory />
    </>
  );
}

export default Aboutus;
