import React from "react";
import { useNavigate } from "react-router-dom";

import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";
import Button from "../elements/Button";

const meetOurTeamConfig = {
  mainText: "Meet the Innovators Simplifying Insurance at Uniblox",
  subText:
    "Are you eager to shape the next era of e-commerce for insurance with us?",
  joinUsButtonText: "Join Us",
  meetTeamText: "MEET OUR TEAM",
};

const JoinUsCareers = () => {
  const navigate = useNavigate();
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 });

  const handleClick = () => {
    navigate("/aboutus");
    setTimeout(scrollToTeamSection, 100); // Scroll after navigating
  };

  const scrollToTeamSection = () => {
    const teamSection = document.getElementById("teamSection");
    if (teamSection) {
      window.scrollTo({
        top: teamSection.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section className="mb-16">
        <div
          className={`mx-10 md:mx-32 bg-[#4B516338] border border-[#9C9C9C] rounded-3xl relative transition-custom duration-custom in-expo ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
        >
          <div className={`px-8 md:px-12 py-8 md:py-16`} ref={ref}>
            <div className="font-poppins flex flex-col lg:flex-row justify-between items-center gap-8">
              <div className="text-center lg:text-left">
                <h3 className="text-custom-white text-3xl lg:text-4xl tracking-wide font-black !leading-[1.2] antialiased mb-5 max-w-xl">
                  {meetOurTeamConfig.mainText}
                </h3>
                <p className="sm:text-xl text-lg w-full text-custom-blue tracking-normal font-normal antialiased max-w-xl">
                  {meetOurTeamConfig.subText}
                </p>
              </div>
              <div className="items-center sm:items-baseline flex flex-col sm:flex-row gap-6">
                <a
                  className="text-white text-xs md:text-base font-500 sm:font-500 text-center md:text-left"
                  href="https://wellfound.com/company/uniblox/jobs"
                >
                  {meetOurTeamConfig.joinUsButtonText}
                </a>
                <Button
                  wideMobile
                  onClick={handleClick}
                  className={`border border-neutral-100 rounded-lg grid items-center font-400 text-custom-white px-6 py-2 text-xs md:text-base bg-[#39393986] text-center md:text-left hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet`}
                >
                  <div>{meetOurTeamConfig.meetTeamText}</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinUsCareers;
