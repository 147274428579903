export const hexToRgb = (
  hex: string,
): {
  r: number;
  g: number;
  b: number;
} => {
  return {
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  };
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const pageColors = {
  100: "#DBE9FE",
  200: "#EFF6FF",
  300: "#C1DCFE",
  primary: "#006ED9",
};

export function setBodyBackground(theme: "light" | "dark") {
  if (theme === "light") {
    document.body.classList.add("bg-white");
    document.body.classList.remove("bg-custom-dark");
  } else {
    document.body.classList.add("bg-custom-dark");
    document.body.classList.remove("bg-white");
  }
}
