import React from "react";

const ProcessingStep = ({
  children,
  text,
  isDisabled,
  value,
  status,
}: {
  children: React.ReactNode;
  text: string;
  isDisabled: boolean;
  value: string;
  status: string | null;
}) => {
  return (
    <div className="relative flex w-fit flex-col justify-end items-center">
      <div
        className={` ${isDisabled ? "bg-white text-black" : "bg-custom-blue-census text-white"} ${value === status ? "animate-pulse" : ""} rounded-full`}
      >
        {children}
      </div>
      <p
        className={`leading-3 absolute text-[10px] md:text-sm font-normal text-black ${text === "Post Processing File" ? "w-16 md:w-32" : "w-22"} ${value === status ? "animate-pulse" : ""} text-center top-12 sm:top-16 mt-1 md:mt-2`}
      >
        {text}
      </p>
    </div>
  );
};

export default ProcessingStep;
