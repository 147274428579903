import React from "react";
import Image from "../elements/Image";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";
import { Separator } from "../layout/Footer";
import cube1 from "../../assets/images/what-we-believe-cubes/cube-1.svg";
import cube2 from "../../assets/images/what-we-believe-cubes/cube-2.png";

const WhatWeBelieve = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 1000 });

  return (
    <section className="bg-custom-dark border-t-gray-700 mb-8 md:mb-28">
      <div
        className={`transition-custom duration-custom in-expo ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <Separator />
      </div>
      <div className="pt-8 md:pt-20">
        <div
          className={`bg-custom-dark transition-custom duration-custom in-expo w-4/5 mx-auto mb-16 ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
          ref={ref}
        >
          <div className="flex justify-center">
            <div
              className={`text-center font-600 text-gray-100 text-xs md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out ${
                revealed
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-2"
              }`}
            >
              ✨ Innovative, Customer-Centric
            </div>
          </div>
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-black text-white font-poppins text-center my-8">
            What we{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD] capitalize">
              believe
            </span>
          </h1>
          <p className="text-center text-sm md:text-xl text-white font-poppins justify-start font-400 w-11/12 mx-auto leading-10 opacity-80">
            We thrive on innovation, customer focus, and integrity as we strive
            to modernize and simplify the insurance ecosystem using technology
          </p>
        </div>
        <section
          className={`px-8 md:px-24 flex flex-col gap-6 md:grid md:grid-cols-2 justify-center md:relative lg:grid-cols-3 relative transition-custom duration-custom in-expo ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
        >
          <div
            className={`hidden md:block absolute -top-[100px] right-[100px] transition-custom duration-custom in-expo ${
              revealed
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-4"
            } -z-10 opacity-90`}
          >
            <img src={cube1} alt="" className="block h-[200px]" />
          </div>
          <div
            className={`hidden md:block absolute -top-[100px] right-1/4 rotate-180 transition-custom duration-custom in-expo ${
              revealed
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-4"
            } -z-10 opacity-90`}
          >
            <img src={cube2} alt="" className="block h-[500px]" />
          </div>
          <div className="h-[276px] w-[276px] absolute opacity-25 blur-[100px] bg-[#D04BFF] left-[88px] -top-[294px] -z-10"></div>
          <div className="h-[276px] w-[276px] absolute opacity-25 blur-[100px] bg-[#4B7DFF] right-[230px] -bottom-[100px] -z-10"></div>
          <article className="px-6 py-6 md:py-11 border border-[#CACACA] rounded-2xl bg-[#3D425C45] col-span-1 flex flex-col text-center backdrop-blur-lg">
            <div className="self-center">
              <Image
                src={require("./../../assets/images/believe-img-1.png")}
                alt="Features tile icon 01"
                className="block mx-auto"
                width={72.79}
              />
            </div>
            <h3 className="text-2xl font-600 text-white py-5 capitalize">
              Customer Obsession
            </h3>
            <p className="text-xs md:text-lg lg:text-xl font-400 text-custom-white">
              Our customers are at the heart of everything we do. Their needs
              and experiences guide our decisions and innovations
            </p>
          </article>
          <article className="px-6 py-6 md:py-11 border border-[#CACACA] rounded-2xl bg-[#3D425C45] col-span-1 flex flex-col text-center backdrop-blur-lg">
            {/*     backdrop-filter: blur(10px); */}
            <div className="self-center">
              <Image
                src={require("./../../assets/images/believe-img-2.png")}
                alt="Features tile icon 01"
                className="block mx-auto"
                width={72.79}
              />
            </div>
            <h3 className="text-2xl font-600 text-white py-5 capitalize">
              Innovation
            </h3>
            <p className="text-xs md:text-lg lg:text-xl font-400 text-custom-white">
              Using cutting-edge technology to transform the insurance industry.
              To make insurance more accessible and user-friendly
            </p>
          </article>
          <div className="md:col-span-2 md:flex md:justify-center lg:block lg:col-span-1 backdrop-blur-lg">
            <article className="px-6 py-6 md:py-11 border border-[#CACACA] rounded-2xl bg-[#3D425C45] items-center md:w-1/2 lg:w-auto flex flex-col text-center">
              <Image
                src={require("./../../assets/images/believe-img-3.png")}
                alt="Features tile icon 01"
                className="block mx-auto"
                width={72.79}
              />
              <h3 className="text-2xl font-600 text-white py-5 capitalize">
                Collaboration
              </h3>
              <p className="text-xs md:text-lg lg:text-xl font-400 text-custom-white">
                Collaborating with insurers, brokers, agents for success.
                Teamwork thrives, goals align, delivering customer-centric
                solution
              </p>
            </article>
          </div>
        </section>
      </div>
    </section>
  );
};

export default WhatWeBelieve;
