import React from "react";
import useRevealOnScroll from "../../hooks/useRevealOnScroll";
import feature2 from "../../../src/assets/images/Product_features.svg";
import { Separator } from "../layout/Footer";

const visionConfig = {
  heading: { mainText: "Our", subText: "Vision" },
  description: `Empowering a future where insurance is as accessible and intuitive
              as e-commerce, driven by modern technology and
              customer-centricity.`,
};

const MissionAndVision = () => {
  const { revealed } = useRevealOnScroll({ time: 600 });

  return (
    <section className="bg-custom-dark">
      <div
        className={`transition-custom duration-custom in-expo ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        } pb-8 md:pb-20`}
      >
        <Separator />
      </div>
      <div className="bg-custom-dark mt-0 md:mt-16 mb-8 w-full sm:w-4/5 m-auto">
        <div className="bg-custom-dark">
          <div
            className={`bg-custom-dark transition-custom duration-custom in-expo w-4/5 mx-auto mb-0 md:mb-16 ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            <div className="flex justify-center">
              <div
                className={`text-center font-600 text-gray-100 text-xs md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out ${
                  revealed
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-2"
                }`}
              >
                ✨ Revolutionizing Insurance for Modern Consumers
              </div>
            </div>
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-black text-white font-poppins text-center my-8">
              {`${visionConfig.heading.mainText} `}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD]">
                {visionConfig.heading.subText}
              </span>
            </h1>
            <p className="text-center text-sm md:text-xl text-white font-poppins justify-start font-400 w-11/12 mx-auto leading-10 opacity-80">
              {visionConfig.description}
            </p>
          </div>
          <div
            className={`flex justify-center h-[500px] bg-no-repeat bg-contain bg-center relative transition-custom duration-custom in-expo ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
            }`}
            style={{ backgroundImage: `url(${feature2})` }}
          >
            <div className="h-[276px] w-[276px] absolute opacity-25 blur-[100px] bg-[#D04BFF] -left-[188px] -top-[294px] -z-10"></div>
            <div className="h-[276px] w-[276px] absolute opacity-25 blur-[100px] bg-[#4B7DFF] -right-[0] bottom-[114px] -z-10"></div>
          </div>
          {/* <section
            className={`px-12 flex flex-col gap-6 md:grid md:grid-cols-2 justify-center md:relative -top-[150px] lg:grid-cols-3 transition-custom duration-custom in-expo ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            <article className="px-6 py-11 border border-[#CACACA] rounded-2xl bg-[#3D425C45] col-span-1">
              <h2 className="text-4xl font-black text-white mb-3">99.9%</h2>
              <h3 className="text-2xl font-600 text-[#42B5CF] mb-3 capitalize">
                Data Accuracy
              </h3>
              <p className="text-sm font-400 text-[#A9A9A9]">lorem ipsum</p>
            </article>
            <article className="px-6 py-11 border border-[#CACACA] rounded-2xl bg-[#3D425C45] col-span-1">
              <h2 className="text-4xl font-black text-white mb-3">13k</h2>
              <h3 className="text-2xl font-600 text-[#42B5CF] mb-3 capitalize">
                happy users
              </h3>
              <p className="text-sm font-400 text-[#A9A9A9]">lorem</p>
            </article>
            <div className="md:col-span-2 md:flex md:justify-center lg:block lg:col-span-1">
              <article className="px-6 py-11 border border-[#CACACA] rounded-2xl bg-[#3D425C45] items-center md:w-1/2 lg:w-auto">
                <h2 className="text-4xl font-black text-white mb-3">17+</h2>
                <h3 className="text-2xl font-600 text-[#42B5CF] mb-3 capitalize">
                  Products
                </h3>
                <p className="text-sm font-400 text-[#A9A9A9]">lorem</p>
              </article>
            </div>
          </section> */}
        </div>
      </div>
    </section>
  );
};

export default MissionAndVision;
