import React, { Fragment } from "react";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";
import { Separator } from "../layout/Footer";

const team = [
  { id: 0, name: "Surya Jakhotia", title: "Founder & CEO", imgSrc: "surya" },
  { id: 1, name: "Tina Chaudhari", title: "Product Manager", imgSrc: "tina" },
  { id: 2, name: "Daniel Bolus", title: "Client Success", imgSrc: "daniel" },
  { id: 3, name: "Shubham Singh", title: "Engineer", imgSrc: "shubham" },
  { id: 4, name: "Ritwik Chavhan", title: "Engineer", imgSrc: "ritwik" },
  { id: 5, name: "Drashty Dadhaniya", title: "Engineer", imgSrc: "drashty" },
  { id: 6, name: "Varshith Kumar", title: "Engineer", imgSrc: "varshith" },
  { id: 7, name: "Nabadeep Thakuria", title: "Engineer", imgSrc: "nabadeep" },
  {
    id: 8,
    name: "Sivasankari Rajamanickam",
    title: "Data Scientist",
    imgSrc: "siva",
  },
  { id: 9, name: "Muskan Chaturvedi", title: "Engineer", imgSrc: "muskan" },
];

const MeetOurTeam = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 900 });
  return (
    <section className="bg-custom-dark">
      <div
        className={`transition-custom duration-custom in-expo ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <Separator />
      </div>
      <div
        id="teamSection"
        className={`transition-custom duration-custom in-expo py-2 mt-20`}
        ref={ref}
      >
        <div
          className={`transition-custom duration-custom in-expo w-4/5 mx-auto mb-8 md:mb-20 ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
        >
          <div className="flex justify-center">
            <div
              className={`text-center font-600 text-gray-100 text-xs md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out ${
                revealed
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-2"
              }`}
            >
              ✨ Expertise. Creativity. Excellence
            </div>
          </div>
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-black text-white font-poppins text-center my-8">
            Meet our{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD]">
              Team
            </span>
          </h1>
          <p className="text-center text-sm md:text-xl text-white font-poppins justify-start font-400 w-11/12 mx-auto leading-10 opacity-80">
            Innovative team crafting exceptional solutions with expertise,
            creativity, and a passion for excellence
          </p>
        </div>
      </div>
      <TeamMembers />
    </section>
  );
};

const TeamMembers = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 900 });
  return (
    <section
      className={`relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-10 md:px-28 gap-10 text-center pb-8 md:pb-32 transition-custom duration-custom in-expo ease-in-out ${
        revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
      }`}
      ref={ref}
    >
      <div className="hidden md:block h-[350px] w-[350px] absolute opacity-25 blur-[100px] bg-[#4B7DFF] right-[230px] bottom-1/3 -z-10"></div>
      <div className="hidden md:block h-[350px] w-[350px] absolute opacity-25 blur-[100px] bg-[#D04BFF] left-0 top-1/4 -z-10"></div>
      {team.map((member) => {
        return (
          <Fragment key={member.id}>
            {member.name ? (
              <article
                className={`bg-[#3D425C59] rounded-2xl flex flex-col py-5 px-6`}
              >
                <div className="mb-6 flex justify-center">
                  <img
                    src={require(
                      `../../../src/assets/images/team/${member.imgSrc}.png`,
                    )}
                    alt={member.imgSrc}
                    width={201}
                    style={{ borderRadius: "100%" }}
                  />
                </div>
                <h4 className="text-[#b7b7b7] text-xl font-700">
                  {member.name}
                </h4>
                <p className="text-[#a6a6a6] text-base font-500">
                  {member.title}
                </p>
              </article>
            ) : (
              <article className=""></article>
            )}
          </Fragment>
        );
      })}
    </section>
  );
};

export default MeetOurTeam;
