import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { setBodyBackground } from "../utils/colorUtils";

const LayoutDefault = ({ children }) => {
  useEffect(() => {
    setBodyBackground("dark");
  }, []);

  return (
    <React.Fragment>
      <Header />
      <main>{children}</main>
      <Footer />
    </React.Fragment>
  );
};

export default LayoutDefault;
