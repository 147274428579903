import React, { useState, useEffect } from "react";
import finch from "../../assets/integrations-element/finch.png";
import selerix from "../../assets/integrations-element/selerix.png";
import mras from "../../assets/integrations-element/mars.png";
import majesco from "../../assets/integrations-element/majesco.png";
import bswift from "../../assets/integrations-element/bswift.png";
import selman from "../../assets/integrations-element/selman.png";
import sendgrid from "../../assets/integrations-element/sendgrid.png";
import empyrean from "../../assets/integrations-element/empyrean.png";
import uniblox from "../../assets/images/Uniblox.svg";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";

const Integrations = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = [
    { src: finch, alt: "finch" },
    { src: selerix, alt: "selerix" },
    { src: mras, alt: "mras" },
    { src: majesco, alt: "majesco" },
    { src: bswift, alt: "bswift" },
    { src: selman, alt: "selman" },
    { src: sendgrid, alt: "sendgrid" },
    { src: empyrean, alt: "empyrean" },
  ];

  const outerRadius = isMobile ? 100 : 220;
  const innerRadius = isMobile ? 60 : 150;

  return (
    <section
      className="sm:pt-[10em] pb-0 sm:pb-[10em] pt-[2em] relative"
      ref={ref}
    >
      <div className="pt-1 pb-1 relative" ref={ref}>
        <div className="relative z-10">
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <figure
              className={`absolute flex justify-center items-center w-full h-full rounded-full border-2 border-custom-grey-light transition-custom duration-custom ease-in-out ${revealed ? "opacity-100" : "opacity-0"}`}
              style={{
                width: `${outerRadius * 2}px`,
                height: `${outerRadius * 2}px`,
              }}
            >
              <div
                className={`absolute rounded-full border-2 border-custom-grey-light transition-custom duration-custom ease-in-out ${revealed ? "opacity-100" : "opacity-0"}`}
                style={{
                  width: `${innerRadius * 2}px`,
                  height: `${innerRadius * 2}px`,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
              <img
                src={uniblox}
                alt="Uniblox Logo"
                className={`absolute w-2/12 sm:w-3/12 h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-custom duration-custom ease-in-out ${revealed ? "opacity-100" : "opacity-0"}`}
              />
              {images.map((image, index) => {
                const angle = (index * Math.PI * 2) / images.length;
                const x = outerRadius * Math.cos(angle);
                const y = outerRadius * Math.sin(angle);

                return (
                  <div
                    key={index}
                    style={{
                      position: "absolute",
                      top: `calc(42% - ${y}px)`,
                      left: `calc(42% + ${x}px)`,
                      width: isMobile ? "40px" : "70px",
                      height: isMobile ? "40px" : "70px",
                      transition: "opacity 0.5s ease-in-out",
                      opacity: revealed ? 1 : 0,
                    }}
                  >
                    <img
                      src={image.src}
                      width={isMobile ? 40 : 70}
                      alt={image.alt}
                      className={`transition-custom duration-custom ease-in-out ${revealed ? "opacity-100" : "opacity-0"}`}
                    />
                  </div>
                );
              })}
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integrations;
