import React from "react";
import { FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";
import { pageColors } from "../../../utils/colorUtils";

const socialLinks = {
  twitter: "https://twitter.com/UniBlox_io",
  github: "https://github.com/uniblox",
  linkedIn: "https://www.linkedin.com/company/uniblox-io/",
};

const FooterSocial = ({ theme }) => {
  return (
    <ul className="flex items-center gap-4 justify-center md:justify-normal">
      <li className="p-2">
        <a href={socialLinks.twitter}>
          <FaTwitter
            size={30}
            style={{
              color: theme === "white" ? pageColors.primary : "#473AD8",
            }}
          />
        </a>
      </li>
      <li className="p-2">
        <a href={socialLinks.github}>
          <FaGithub
            size={30}
            style={{
              color: theme === "white" ? pageColors.primary : "#473AD8",
            }}
          />
        </a>
      </li>
      <li className="">
        <a href={socialLinks.linkedIn}>
          <FaLinkedin
            size={30}
            style={{
              color: theme === "white" ? pageColors.primary : "#473AD8",
            }}
          />
        </a>
      </li>
    </ul>
  );
};

export default FooterSocial;
