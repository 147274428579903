import React from "react";
import Button from "../elements/Button";
import Image from "../elements/Image";
import dashboardImage from "../../assets/images/Dashboard.svg";
import useRevealOnScroll from "../../hooks/useRevealOnScroll";

const heroConfig = {
  heading: "Ecommerce for Insurance Products",
  description:
    "No more legacy systems, manual workflows, and paper-based processes. Move your business forward.",
  bookDemoText: "BOOK A DEMO",
};

const HeroFull = () => {
  const { revealed } = useRevealOnScroll({ time: 800 });

  return (
    <section
      className={`bg-custom-dark w-full flex mx-auto justify-center relative`}
    >
      <div className="h-[300px] w-[300px] md:h-[500px] md:w-[500px] lg:h-[775px] lg:w-[775px] absolute border border-red-50 fill-[#4B7DFF] bg-[#4B7DFF] -top-[60px] -left-[90px] lg:-top-[182px] lg:-left-[226px] opacity-25 blur-3xl -z-5 rounded-full"></div>
      <div className="pt-32 flex justify-center">
        <div className="text-center flex place-items-center flex-col">
          <div
            className={`font-600 text-gray-100 text-xs md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out text-center ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            ✨ Powered by industry-leading technology
          </div>
          <h1
            className={`text-custom-white text-4xl md:text-6xl lg:text-7xl antialiased font-poppins font-600 m-auto sm:w-full transition-custom duration-custom ease-in-out max-w-5xl ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            } py-8 font-black`}
          >
            {heroConfig.heading}
          </h1>
          <div
            className={`flex justify-center transition-custom duration-custom in-expo ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            <p className="text-custom-blue sm:pb-8 text-sm md:text-xl mb-8 sm:mb-0 tracking-wide font-poppins text-center max-w-80 md:max-w-2xl">
              {heroConfig.description}
            </p>
          </div>
          <div
            className={`transition-custom duration-custom in-expo sm:max-h-12 flex items-center justify-center ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            <Button
              tag="a"
              wideMobile
              href="https://calendly.com/uniblox/30min"
              target="_blank"
              className={`h-10 border border-neutral-100 rounded-lg grid items-center font-400 text-white opacity-95 px-6 text-xs md:text-base bg-custom-button-color hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet`}
            >
              <div>{heroConfig.bookDemoText}</div>
            </Button>
          </div>
          <div
            className={`sm:p-20 rounded-5xl p-10 mt-16 m-auto relative transition-custom duration-custom in-expo ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            <div className="hidden lg:block h-[176px] w-[176px] lg:h-[476px] lg:w-[476px] absolute opacity-25 blur-[100px] bg-[#D04BFF] -right-[0px] -top-[294px] -z-10 rounded-lg"></div>
            <Image
              src={dashboardImage}
              alt="Hero"
              width={900}
              height={900}
              className="rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroFull;
