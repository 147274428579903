import React from "react";
import Image from "../elements/Image";
import { offersConfig } from "../../services/offersConfig";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";
import feature1 from "../../../src/assets/images/Uniblox_feature1.svg";
import feature2 from "../../../src/assets/images/Uniblox_feature2.svg";
import feature3 from "../../../src/assets/images/Uniblox_feature3.svg";
import cube1 from "../../../src/assets/images/offer_cube1.png";
import cube2 from "../../../src/assets/images/offer_cube2.png";

const platFormOffersConfig = {
  heading: {
    mainText: "What we",
    highlightedText: "Offer",
  },
  description:
    "Simplifying Insurance Distribution - Your Products, Our Platform, Limitless Possibilities",
};

const PlatformOffers = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 });

  return (
    <section className="bg-custom-dark mb-12">
      <div className="text-center m-auto w-full mb-32 md:mb-72">
        <div className="flex justify-center">
          <div
            className={`text-center font-600 text-gray-100 text-xs md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out mt-10  ${
              revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
            }`}
          >
            ✨ Features
          </div>
        </div>
        <h2
          className={`text-4xl md:text-6xl lg:text-7xl py-8 w-4/5 mx-auto font-poppins text-custom-white border-t-gray-700 font-semibold tracking-wide antialiased transition-custom duration-custom ease-in-out ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
        >
          {`${platFormOffersConfig.heading.mainText} `}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD] inline-block">
            {platFormOffersConfig.heading.highlightedText}
          </span>
        </h2>
        <p
          className={`text-custom-blue w-4/5 m-auto text-center pb-6 font-poppins font-400 scale-x-100 tracking-wide antialiased text-sm md:text-xl max-w-80 md:max-w-2xl transition-custom duration-custom ease-in-out ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
        >
          {platFormOffersConfig.description}
        </p>
        <div
          className={`w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-10 relative transition-custom duration-custom in-expo sm:pt-14 ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
          ref={ref}
        >
          <div className="hidden lg:block bg-[#D04BFF] h-[409px] w-[409px] opacity-25 blur-[100px] -z-1 absolute"></div>
          <div className="hidden lg:block absolute -left-[100px] md:left-[200px] -z-10 top-[100px] md:-top-[10px] opacity-90">
            <img src={cube1} alt="" className="block h-[250px]" />
          </div>
          <div className="hidden md:block absolute right-[100px] md:right-[200px] -z-10 -bottom-[100px] md:bottom-[10px] opacity-90">
            <img src={cube2} alt="" className="block h-[400px]" />
          </div>
          {offersConfig.map((offerInfo) => (
            <div
              className="px-2 flex flex-col items-center bg-transparent-box-color rounded-3xl border border-transparent-box-border-color backdrop-blur-md"
              key={offerInfo.id}
            >
              <div className="block mx-auto pt-6">
                <Image
                  src={require(`./../../assets/images/${offerInfo.imgSrc}`)}
                  alt={offerInfo.altText}
                  width={72.79}
                />
              </div>

              <div className="text-custom-white-light mt-6 mb-8 text-sm md:text-lg font-poppins font-400 scale-x-100 tracking-wide antialiased max-w-md">
                <p className="mx-2">{offerInfo.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Features />
    </section>
  );
};

const Features = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 });
  return (
    <section ref={ref} className={"px-[7vw]"}>
      <article
        className={`md:flex md:justify-between md:gap-24 text-center lg:text-left pb-10 md:pb-28 items-center transition-custom duration-custom ease-in-out ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <div>
          <h3 className="text-white text-4xl md:text-5xl font-black">
            Safe, Trusted, and Secure
          </h3>
          <p className="text-custom-blue py-7 font-poppins font-400 scale-x-100 tracking-wide antialiased text-sm md:text-xl md:max-w-2xl">
            {`Uniblox's security protocols are par to none and include
            out-of-the-box SOC-2 compliance, IP-Address safe listing, and SAML
            Auth`}
          </p>
        </div>
        <div className="relative">
          <Image src={feature1} alt="Hero" width={535} height={352} />
          <div className="hidden lg:block -z-10 h-[128px] w-[128px] lg:h-[428px] lg:w-[428px] absolute opacity-25 blur-[100px] bg-[#D04BFF] left-0 -top-[100px] md:top-0"></div>
        </div>
      </article>
      <article
        className={`md:flex md:justify-between md:gap-24 text-center lg:text-left pb-10 md:pb-28 items-center transition-custom duration-custom ease-in-out ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <div className="block md:hidden">
          <h3 className="text-white text-4xl md:text-5xl font-black">
            Seamless Connectivity
          </h3>
          <p className="text-custom-blue py-7 font-poppins font-400 scale-x-100 tracking-wide antialiased text-sm md:text-xl md:max-w-2xl">
            Uniblox integrates with 100+ BenAdmin platforms, PAS, and other
            functional blocks
          </p>
        </div>
        <div className="relative">
          <Image src={feature2} alt="Hero" width={535} height={352} />
          <div className="h-[820px] w-[820px] absolute opacity-25 blur-[100px] bg-[#4B7DFF] right-[230px] -bottom-1/2 -z-10"></div>
        </div>
        <div className="hidden md:block">
          <h3 className="text-white text-4xl md:text-5xl font-black">
            Seamless Connectivity
          </h3>
          <p className="text-custom-blue py-7 font-poppins font-400 scale-x-100 tracking-wide antialiased text-sm md:text-xl md:max-w-2xl">
            Uniblox integrates with 100+ BenAdmin platforms, PAS, and other
            functional blocks
          </p>
        </div>
      </article>
      <article
        className={`md:flex md:justify-between md:gap-24 text-center lg:text-left pb-10 md:pb-28 items-center transition-custom duration-custom ease-in-out ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <div>
          <h3 className="text-white text-4xl md:text-5xl font-black">
            Fast and Scalable
          </h3>
          <p className="text-custom-blue py-7 font-poppins font-400 scale-x-100 tracking-wide antialiased text-sm md:text-xl md:max-w-2xl">
            Launch and deliver your insurance products at record speed with a
            reliable platform that auto-scales as you grow.
          </p>
        </div>
        <div>
          <Image src={feature3} alt="Hero" width={535} height={352} />
        </div>
      </article>
    </section>
  );
};

export default PlatformOffers;
