import React, { useEffect, useState } from "react";

import { fetchCaseStudy } from "../services";

const GetCaseStudy = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPdfLink = async () => {
      try {
        const url = new URL(window.location);
        const queryparams = url.search.split("?")[1].split("&");
        const params = new URLSearchParams();

        for (const param of queryparams) {
          const arr = param.split("=");
          params.append(arr[0], encodeURIComponent(arr[1]));
        }

        const response = await fetchCaseStudy({ params });

        if (response.data.link) {
          setPdfUrl(response.data.link);
          setError("");
        } else {
          setError("Failed to retrieve the Case Study.");
        }
      } catch (error) {
        setError("Failed to retrieve the case study");
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdfLink();
  }, []);

  return (
    <div className="bg-white min-h-screen">
      {isLoading ? (
        <div className="loading-indicator">
          <div className="spinner"></div>
          Loading...
        </div>
      ) : pdfUrl ? (
        <embed
          className="w-full h-screen"
          src={pdfUrl}
          type="application/pdf"
        />
      ) : (
        <p className="error-message">{error}</p>
      )}
    </div>
  );
};

export default GetCaseStudy;
