import ReactGA from "react-ga4";
import { createBrowserRouter } from "react-router-dom";

import Home from "../views/Home";
import { Navigate } from "react-router-dom";
import LayoutDefault from "../layouts/LayoutDefault";
import GetCaseStudy from "../views/GetCaseStudy";
import Aboutus from "../views/Aboutus";
import LayoutSignin from "../layouts/LayoutSignin";
import Signup from "../views/Signup";
import CaseStudy from "../views/CaseStudy";
import Login from "../views/Login";
import Census from "../views/Census";
import Register from "../components/sections/Register";
import CaseDetails from "../views/CaseDetails";
import LayoutCensus from "../layouts/LayoutCensus";
import Notfound from "../views/Notfound";

ReactGA.initialize("G-1KCC5ZGDV2");
ReactGA.send("pageview");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LayoutDefault>
        <Home />
      </LayoutDefault>
    ),
  },
  {
    path: "/case-study",
    element: (
      <LayoutDefault>
        <CaseStudy />
      </LayoutDefault>
    ),
  },
  {
    path: "/case-study/download",
    element: <GetCaseStudy />,
  },
  {
    path: "/register",
    element: (
      <LayoutDefault>
        <Register />
      </LayoutDefault>
    ),
  },
  {
    path: "/aboutus",
    element: (
      <LayoutDefault>
        <Aboutus />
      </LayoutDefault>
    ),
  },
  {
    path: "/tools",
    element: <Navigate to="/tools/census" replace />,
  },
  {
    path: "/tools/census",
    element: (
      <LayoutCensus>
        <Census />
      </LayoutCensus>
    ),
  },
  {
    path: "/tools/census/:id",
    element: (
      <LayoutCensus>
        <CaseDetails />
      </LayoutCensus>
    ),
  },
  {
    path: "/login",
    element: (
      <LayoutSignin>
        <Login />
      </LayoutSignin>
    ),
  },
  {
    path: "/signup",
    element: (
      <LayoutSignin>
        <Signup />
      </LayoutSignin>
    ),
  },
  {
    path: "*", // Catch-all route for unmatched paths
    element: <Notfound />,
  },
]);

export default router;
