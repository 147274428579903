import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import { setBodyBackground } from "../utils/colorUtils";

const LayoutSignin = ({ children }) => {
  useEffect(() => {
    setBodyBackground("dark");
  }, []);

  return (
    <React.Fragment>
      <Header navPosition="right" hideNav />
      <main className="site-content">{children}</main>
    </React.Fragment>
  );
};

export default LayoutSignin;
