import React from "react";
import Logo from "../layout/partials/Logo";
import useRevealOnScroll from "../../hooks/useRevealOnScroll";

const AboutusHero = () => {
  const { revealed } = useRevealOnScroll({ time: 600 });
  return (
    <section
      className={`relative py-8 md:py-28 flex flex-col md:flex-row text-center md:text-left gap-12 md:gap-44 w-full md:w-4/5 m-auto justify-between items-center transition-custom duration-custom ease-in-out ${
        revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
      }`}
    >
      <div className="hidden md:block h-[300px] w-[300px] md:h-[500px] md:w-[500px] lg:h-[555px] lg:w-[555px] absolute border border-red-50 fill-[#4B7DFF] bg-[#4B7DFF] -top-[60px] -right-[40px] lg:-top-[152px] lg:-right-[110px] opacity-25 blur-3xl rounded-full -z-10"></div>
      <article className="mx-8">
        <h3 className="capitalize text-white font-black text-4xl md:text-5xl mb-8">
          About us
        </h3>
        <p className="max-w-xl text-[#d8d8d8] text-sm md:text-xl">
          We thrive on innovation, customer focus, and integrity as we strive to
          modernize and simplify the insurance ecosystem using technology
        </p>
      </article>
      <Logo width={238} height={228} />
    </section>
  );
};

export default AboutusHero;
