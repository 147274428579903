import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from "../services/axiosInstance";
import useRevealOnScroll from "../hooks/useRevealOnScroll";
import Button from "../components/elements/Button";
import cube1 from "../../src/assets/images/case-study-cubes/cube-1.svg";
import cube2 from "../../src/assets/images/case-study-cubes/cube-2.svg";
import cube3 from "../../src/assets/images/case-study-cubes/cube-3.svg";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const discouragedEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
];

const caseStudyConfig = {
  mainText: {
    heading: "Transforming EOI in 10 Weeks",
    subText:
      "Slashing Decision Time from 60+ Days to Same-Day for 65% of Cases.",
  },
  subText: `Unveil the story of a leading insurance firm's journey to
                redefine its EOI process, marrying modern customer experience
                with unprecedented operational efficiency. Witness the prowess
                of contemporary solutions in addressing timeless industry
                challenges.`,
};

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email")
    .required("Email is required"),
});

const CaseStudy = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [enteredEmailDomain, setEnteredEmailDomain] = useState("");
  const [caseStudyNotFoundError, setcaseStudyNotFoundError] = useState(false);

  const { revealed } = useRevealOnScroll({ time: 600 });

  const onSubmit = async (data) => {
    try {
      const domain = data.email.split("@")[1];
      if (discouragedEmailDomains.includes(domain)) {
        setServerErrorMessage("Please provide a work email address.");
        setEnteredEmailDomain(domain);
        setSuccessMessage("");
        setcaseStudyNotFoundError(false);
        return;
      }

      const response = await axiosInstance.post("/main/case-study", {
        ...data,
        caseStudyName: "case-study-sya1.pdf",
      });

      if (response.status === 200) {
        setSuccessMessage(
          "Your case study link has been mailed to your inbox.",
        );
        setServerErrorMessage("");
        setEnteredEmailDomain("");
        setcaseStudyNotFoundError(false);
      } else if (response.status === 404) {
        setcaseStudyNotFoundError(true);
        setSuccessMessage("");
        setServerErrorMessage("");
      } else {
        let errorMessage =
          "Please make sure that the provided details are valid";

        if (response.status === 400) {
          errorMessage = response.data.message || errorMessage;
        } else if (response.status === 403) {
          errorMessage = "You do not have permission to access this resource.";
        }

        console.error(
          `Request failed with status ${response.status}: ${errorMessage}`,
        );
        setSuccessMessage("");
        setServerErrorMessage(errorMessage);
        setcaseStudyNotFoundError(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setSuccessMessage("");
      setServerErrorMessage(
        "Please make sure that the provided details are valid.",
      );
      setcaseStudyNotFoundError(false);
    }
  };

  return (
    <div className="bg-custom-dark mb-24">
      <section className=" relative">
        <div
          className={`absolute opacity-50 -top-[100px] right-1/4 transition-custom duration-custom in-expo ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
          }`}
        >
          <img src={cube1} alt="" className="block h-[250px]" />
        </div>
        <div
          className={`hidden md:block absolute top-1/3 right-0 transition-custom duration-custom in-expo ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
          }`}
        >
          <img src={cube2} alt="" className="block h-[250px]" />
        </div>
        <div
          className={`absolute bottom-0 right-1/4 transition-custom duration-custom in-expo ${
            revealed ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
          }`}
        >
          <img src={cube3} alt="" className="block h-[250px]" />
        </div>
        <div className="mt-28">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center px-8 md:px-24 justify-between gap-14">
            <div
              className={`transition-custom duration-custom in-expo relative ${
                revealed
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 -translate-y-4"
              }`}
            >
              <div className="h-[200px] md:h-[400px] w-[200px] md:w-[400px] absolute opacity-25 blur-[100px] bg-[#D04BFF] left-[25%] top-full md:top-1/2 -z-10"></div>
              <div className="h-[200px] md:h-[400px] w-[200px] md:w-[400px] absolute opacity-25 blur-[100px] bg-[#4B7DFF] top-0 md:top-auto -left-1/3 -bottom-1/4 -z-10"></div>
              <h3 className="text-4xl md:text-5xl lg:text-6xl font-black leading-10 font-poppins text-center sm:text-left text-white sm:leading-relaxed mx-auto">
                {caseStudyConfig.mainText.heading}
              </h3>
              <h4 className="text-white text-2xl md:text-3xl font-black my-8 text-center md:text-left">
                {caseStudyConfig.mainText.subText}
              </h4>
              <p className="text-sm md:text-lg text-[#d8d8d8] text-center sm:text-left font-poppins font-500 text-[18px] sm:text-lg">
                {caseStudyConfig.subText}
              </p>
            </div>
            <div
              className={`rounded-xl transition-custom duration-custom in-expo text-white border border-white backdrop-blur-sm ${
                revealed
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 -translate-y-4"
              }`}
              style={{
                background:
                  "linear-gradient(130deg, rgba(116, 146, 255, 0.35) 3.05%, rgba(46, 0, 44, 0.46) 95.86%)",
              }}
            >
              <form
                className="text-center px-11"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h2 className="font-poppins font-600 text-3xl md:text-4xl lg:text-5xl mt-12 antialised leading-8">
                  Unlock the case study
                </h2>
                <p className="mt-8 mb-6 text-base font-poppins font-500 antialised">
                  Complete the form below to receive a free copy of the case
                  study directly to your inbox.
                </p>
                <div className="grid grid-cols-2 gap-2">
                  <div className="antialiased col-span-1">
                    <input
                      type="text"
                      className={`focus:outline-none focus:border-[#482BD9] focus:ring-1 focus:ring-[#41D6CD] w-full rounded-l-3xl p-2 pl-5 text-base bg-[#251930] placeholder:text-base placeholder:pl-0 md:placeholder:pl-5 ${
                        errors.firstName ? "input-with-error" : ""
                      }`}
                      placeholder="First name"
                      {...register("firstName")}
                    />
                    {errors.firstName && (
                      <p className="error-message">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="antialiased col-span-1">
                    <input
                      type="text"
                      className={`focus:outline-none focus:border-[#482BD9] focus:ring-1 focus:ring-[#41D6CD] w-full rounded-r-3xl p-2 pl-5 text-base bg-[#251930] placeholder:text-base placeholder:pl-0 md:placeholder:pl-5 ${
                        errors.lastName ? "input-with-error" : ""
                      }`}
                      placeholder="Last name"
                      {...register("lastName")}
                    />
                    {errors.lastName && (
                      <p className="error-message">{errors.lastName.message}</p>
                    )}
                  </div>
                  <div className="antialiased col-span-2">
                    <input
                      type="email"
                      className={`focus:outline-none focus:border-[#482BD9] focus:ring-1 focus:ring-[#41D6CD] w-full p-2 pl-5 mt-2 text-base bg-[#251930] placeholder:text-base placeholder:pl-0 md:placeholder:pl-5 rounded-3xl ${
                        errors.email ? "input-with-error" : ""
                      }`}
                      placeholder="Work Email Address"
                      {...register("email")}
                    />
                    {errors.email && (
                      <p className="error-message">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-center text-center mb-9 mt-12 w-full rounded-lg">
                  <Button
                    wideMobile
                    className={`h-10 border border-neutral-100 rounded-lg grid items-center font-400 text-white opacity-95 px-6 text-xs md:text-base bg-custom-button-color hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet`}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
                {successMessage && !serverErrorMessage && (
                  <p className="successmsg text-green-500 mb-2 text-center">
                    {successMessage}
                  </p>
                )}
                {serverErrorMessage && enteredEmailDomain && (
                  <p className="errormsg text-xs text-red-500 text-center -mt-4 mb-2">
                    {`The email domain "${enteredEmailDomain}" is not allowed.
                    Please provide a work email address.`}
                  </p>
                )}
                {caseStudyNotFoundError && (
                  <p className="errormsg text-xs text-red-500 text-center -mt-4 mb-2">
                    Something went wrong
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaseStudy;
