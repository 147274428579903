import React from "react";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import img from "./../../../assets/images/Uniblox.svg";

const Logo = ({ width, height }) => {
  return (
    <div className="flex items-center">
      <h1>
        <Link to="/" className="relative">
          <Image
            src={img}
            alt="Uniblox"
            className="rounded-sm"
            width={width ?? 36}
            height={height ?? 43}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
