import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { RiLoader2Line } from "react-icons/ri";
import { FcOk } from "react-icons/fc";

import { checkFileStatus, triggerCaseWorkflow } from "../services";
import { fileStatus } from "../constants";
import CopyToClipBoardText from "../components/elements/CopyToClipBoard";
import FileProcessingStatus from "../components/sections/census/FileProcessingStatus";
import { FaArrowRight, FaSpinner } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";

const CASE_CREATION_EMAIL = "grpuw+group-uw@uniblox.dev";

const Census = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [intervalDuration, setIntervalDuration] = useState(15000);

  const [caseId, setCaseId] = useState<string | undefined>(undefined);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileProcessingStatus, setFileProcessingStatus] = useState<
    string | null
  >(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { getRootProps, isDragActive } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
      "text/csv": [".csv"],
    },
    onDrop: useCallback((files: any) => {
      const selectedFile = files[0];
      if (selectedFile) {
        const fileSizeInMB = selectedFile.size / (1024 * 1024);
        if (fileSizeInMB > 5) {
          setError("File size should not exceed 5 MB");
          return;
        }
      }
      setFile(selectedFile);
      setIsProcessingFile(true);
      setError("");
    }, []),
  });

  useEffect(() => {
    const checkStatus = async () => {
      if (caseId) {
        const response = await checkFileStatus(caseId);
        if (response.data.data.status === fileStatus.COMPLETED) {
          setFileProcessingStatus(fileStatus.COMPLETED);
          clearInterval(intervalId);
          const completedIntervalId = setTimeout(() => {
            setIsProcessingFile(false);
            navigate(`/tools/census/${caseId}`);
          }, 5000);
          return () => clearInterval(completedIntervalId);
        }
        if (response.data.data.status === fileStatus.ERROR) {
          setError("There was an error in processing your file");
          setIsProcessingFile(false);
          clearInterval(intervalId);
        }
        setFileProcessingStatus(response.data.data.status);
      }
    };

    const updateIntervalDuration = () => {
      if (
        fileProcessingStatus === fileStatus.DEDUPLICATING ||
        fileProcessingStatus === fileStatus.POSTPROCESSING
      ) {
        setIntervalDuration(3000);
      } else {
        setIntervalDuration(15000);
      }
    };

    updateIntervalDuration();

    const intervalId = setInterval(() => {
      if (!caseId) {
        clearInterval(intervalId);
      }
      checkStatus();
    }, intervalDuration);

    // Cleanup the interval when the component unmounts or caseId changes
    return () => clearInterval(intervalId);
  }, [caseId, intervalDuration]);

  useEffect(() => {
    if (file) {
      handleSubmit();
    }
  }, [file]);

  const handleSubmit = async () => {
    try {
      setFileProcessingStatus(fileStatus.UPLOADING);
      const formData = new FormData();
      if (file) {
        formData.append("file", file, file.name);
      }

      const response = await triggerCaseWorkflow(formData);
      const caseIdFromResponse = response.data.data.caseId;
      setCaseId(caseIdFromResponse);
    } catch (error) {
      setError("Failed to submit file");
    }
  };

  const handleSubmitEmail = async (e: any) => {
    e.preventDefault();
    try {
      if (!caseId) return;
      setEmailLoading(true);
      const formData = new FormData(e.target as HTMLFormElement);

      formData.append("caseId", caseId);
      await triggerCaseWorkflow(formData);
      const emailFromForm = formData.get("email") as string;
      setEmail(emailFromForm);
    } catch (error) {
      console.error(error);
    } finally {
      setEmailLoading(false);
    }
  };

  const handleFileSubmit = (e: any) => {
    e.preventDefault();
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: any) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        setError("File size should not exceed 5 MB");
        return;
      }
    }
    setFile(selectedFile);
    setIsProcessingFile(true);
    setError("");
  };

  const handleRetry = () => {
    setFile(undefined);
    setError(undefined);
    setCaseId(undefined);
    setFileProcessingStatus(null);
  };

  return (
    <div className="flex justify-center text-base mt-14 md:mt-24 mb-10 w-full transition-all ease-out duration-200">
      <div className="flex flex-col justify-center items-center w-fit mx-4 md:mx-24">
        <div className="flex justify-center">
          <div className="text-center font-500 text-gray-100 text-sm md:text-base lg:text-base rounded-3xl bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet px-3 py-1 sm:py-2 sm:px-6 transition-custom duration-custom ease-in-out opacity-100 translate-y-0">
            ✨ Powered by industry-leading technology
          </div>
        </div>
        <div className="mt-6 flex flex-col justify-center items-center gap-1">
          <p className="text-4xl sm:text-7xl text-center font-bold">
            Census Parser
          </p>
          <p className="text-lg mt-1 sm:mt-0 sm:text-xl w-fit sm:w-2/3 text-center">
            AI/ML-powered data cleansing, parsing, and merging of files/sheets
            for streamlined group underwriting
          </p>
        </div>

        <div className="flex flex-col justify-center items-center transition-all ease-out duration-400 bg-custom-bg-blue mt-14 rounded-3xl border-2 border-dashed border-custom-blue-census w-full">
          {error || fileProcessingStatus === "error" ? (
            <div className="flex flex-col items-center justify-center gap-2 w-full sm:w-3/4  px-4 md:mx-96 py-12 md:py-20">
              <img src="/tabler_face_error.svg" className="h-20 w-20" />
              <h3 className="text-custom-danger text-center text-xl font-bold">
                {error}
              </h3>
              <button
                className="w-24 border-none hover:ring-2 bg-custom-blue-census text-white font-500 h-9 text-sm items-center justify-self-center sm:justify-self-end rounded-full px-3 my-3"
                onClick={handleRetry}
              >
                RETRY
              </button>

              {(error || fileProcessingStatus === "error") && caseId && (
                <form
                  onSubmit={handleSubmitEmail}
                  className="flex flex-col gap-4 lg:flex-row  justify-between lg:items-center my-4 bg-white rounded-xl shadow-md mt-10 sm:mt-20 md:mx-10 px-4 mx-4 md:px-10 py-5 sm:py-10 w-full"
                >
                  <div className="flex flex-col gap-1">
                    <p className="text-lg font-medium tracking-wide text-left">
                      Need help processing your file?
                    </p>

                    <p className="mt-2 w-fit text-sm text-gray-500 md:text-left">
                      Share your email ID & our team will get back to you
                    </p>
                  </div>
                  <div className="flex flex-col w-full lg:w-1/2">
                    <p
                      className={`text-xs mt-2 md:my-2 ${email ? "visible" : "hidden"}`}
                    >
                      You will be notified at
                    </p>

                    <div className="flex w-full relative">
                      <input
                        name="email"
                        disabled={!!email}
                        className={`mt-2 sm:mt-0 relative 
                          sm:-translate-x-0 w-full text-sm md:text-base h-12 ring-2 ring-gray-200 outline-none rounded-full pl-2 ${email ? "cursor-not-allowed" : ""}`}
                        placeholder="Enter your work email"
                      />

                      <button
                        className={`absolute top-3 right-2 sm:top-1 sm:mr-0 sm:translate-x-0 sm:right-1 p-3 rounded-full transition-all duration-300 ${
                          !email ? "bg-custom-blue-census" : "bg-green-600"
                        }`}
                        disabled={!!email}
                      >
                        {emailLoading ? (
                          <FaSpinner className="animate-spin text-white" />
                        ) : (
                          <>
                            {!email ? (
                              <FaArrowRight size={16} className="text-white" />
                            ) : (
                              <IoMdCheckmark size={16} className="text-white" />
                            )}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          ) : (
            <>
              {!isProcessingFile && (
                <div
                  {...getRootProps()}
                  className="flex flex-col justify-center items-center text-center w-full py-12 md:py-20"
                >
                  <div
                    className={`${isDragActive ? "opacity-50" : ""} text-custom-blue-census`}
                  >
                    <FiUploadCloud size={100} strokeWidth={1.2} />
                  </div>
                  <input
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    id="file"
                    type="file"
                    name="files"
                    accept=".xlsx,.xls,.csv"
                    hidden
                  />
                  {isDragActive ? (
                    <p className="mt-2 text-xl opacity-40 font-bold tracking-wide">
                      Drop your files here
                    </p>
                  ) : (
                    <p className="mt-2 text-xl font-bold tracking-wide">
                      Drag & drop files or{" "}
                      <button
                        onClick={handleFileSubmit}
                        className="text-custom-blue-census underline hover:scale-105"
                      >
                        Browse
                      </button>
                    </p>
                  )}
                  <p
                    className={`${isDragActive ? "opacity-50" : ""} mt-2 mb-6 px-4 font-normal text-sm text-gray-500 tracking-normal md:tracking-wide text-center`}
                  >
                    Supported formats: CSV, XLS, XLSX size
                    less&nbsp;than&nbsp;5MB
                  </p>
                  {error}
                </div>
              )}
              {isProcessingFile && (
                <div className="flex flex-col justify-center items-center gap-3 mx-0 md:mx-96 px-2 py-12 md:py-20">
                  <div className="flex px-4 py-3 gap-2 items-center bg-white rounded-lg">
                    <div>
                      {file?.type === "text/csv" ? (
                        <img src="/csvFileIcon.svg" height={32} width={30} />
                      ) : (
                        <img src="/xlSheetFileIcon.svg" />
                      )}
                    </div>
                    <div className="flex flex-col items-start justify-center ml-2">
                      <p>{file?.name}</p>
                      <p className="text-gray-500 flex justify-center items-center gap-2">
                        {fileProcessingStatus === fileStatus.UPLOADING ? (
                          <div className="text-custom-blue-census animate-spin">
                            <RiLoader2Line />
                          </div>
                        ) : (
                          <div className="text-custom-blue-census">
                            <FcOk />
                          </div>
                        )}
                        {fileProcessingStatus === fileStatus.UPLOADING
                          ? "Uploading..."
                          : "Completed"}
                      </p>
                    </div>
                  </div>
                  <p className="font-bold tracking-wide text-center text-2xl mt-2">
                    {fileProcessingStatus === fileStatus.UPLOADING
                      ? "Uploading"
                      : "Processing"}{" "}
                    your file...
                  </p>
                  <p className="font-normal text-sm text-gray-500 tracking-normal sm:tracking-wide text-center">
                    Supported formats: CSV, XLS, XLSX size
                    less&nbsp;than&nbsp;5MB
                  </p>
                </div>
              )}

              {isProcessingFile &&
                fileProcessingStatus !== fileStatus.UPLOADING && (
                  <div className="flex flex-col items-center justify-center -mt-6 sm:mt-6">
                    <FileProcessingStatus status={fileProcessingStatus} />
                    <form
                      onSubmit={handleSubmitEmail}
                      className="flex flex-col md:flex-row items-center justify-between bg-white rounded-xl shadow-md my-10 sm:my-20  mx-4 md:mx-20 px-4 md:px-6 py-5 sm:py-10"
                    >
                      <div className="flex flex-col gap-1">
                        <p className="text-sm sm:text-lg w-fit font-medium tracking-tight md:tracking-wide text-left">
                          Don’t want to wait and watch while your file is
                          getting processed?
                        </p>
                        <p className="mt-2 w-fit text-sm text-gray-500 text-left">
                          Enter your email, and we&apos;ll send your census file
                          as soon as it&apos;s ready.
                        </p>
                      </div>
                      <div className="flex flex-col w-full md:w-1/2">
                        <p
                          className={`text-xs mt-2 md:my-2 ${email ? "visible" : "hidden"}`}
                        >
                          You will be notified at
                        </p>

                        <div className="flex w-full relative">
                          <input
                            name="email"
                            disabled={!!email}
                            className={`mt-2 md:mt-0 relative w-full h-12 ring-2 text-sm lg:text-base ring-gray-200 outline-none rounded-full pl-2 ${email ? "cursor-not-allowed" : ""}`}
                            placeholder="Enter your work email"
                          />

                          <button
                            className={`absolute top-3 right-2 md:top-1  sm:mr-0 sm:right-1 p-3 rounded-full transition-all duration-300 ${
                              !email ? "bg-custom-blue-census" : "bg-green-600"
                            }`}
                            disabled={!!email}
                          >
                            {emailLoading ? (
                              <FaSpinner className="animate-spin text-white" />
                            ) : (
                              <>
                                {!email ? (
                                  <FaArrowRight
                                    size={16}
                                    className="text-white"
                                  />
                                ) : (
                                  <IoMdCheckmark
                                    size={16}
                                    className="text-white"
                                  />
                                )}
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
            </>
          )}
        </div>

        <div className="w-full text-black border flex  flex-col-reverse lg:flex-row  gap-2 max-w-7xl lg:justify-between rounded-3xl overflow-hidden shadow-md px-8 xl:px-10 py-10 my-10">
          <div className="relative flex items-center justify-center lg:justify-start w-full">
            <div className="flex justify-center">
              <div className="p-6 md:p-3 bg-white text-center z-10">
                <span className="text-xl font-bold  lg:hidden">
                  Scan QR to open Email App
                </span>
                <img
                  width="240"
                  height="240"
                  src="/groupQR.png"
                  className=" bg-white"
                />
              </div>
              <div className="relative hidden lg:block top-14 xl:top-20 -left-2 h-20 z-20 ">
                <img src="/swirled_arrow.svg" className="h-3/4 xl:h-full" />
                <div className="font-medium text-center text-sm bg-white xl:max-w-48 px-4 pt-1 pb-2">
                  Scan QR to open email app
                </div>
              </div>
              <img
                src="/uniblox_light_logo.png"
                className="w-64 h-80 2xl:h-large 2xl:w-large absolute top-28 md:top-16 left-28 md:left-28"
              />
            </div>
          </div>

          <div className="text-gray-300 text-center text-sm lg:hidden mt-8">
            -------------- or ---------------
          </div>

          <div className="flex flex-col items-center lg:items-end w-full justify-center gap-3 md:gap-7 xl:max-w-md text-center xl:text-right md:pr-6 xl:pr-0">
            <h3 className="font-bold text-2xl md:text-4xl max-w-sm">
              Want us to do the heavy lifting?
            </h3>
            <p className="text-gray-500 text-xs md:text-base">
              Attach your census file and email us. We’ll send you a link once
              your file is processed.
            </p>
            <div className="flex items-center gap-2 border-2 rounded-full  text-gray-500 p-1">
              <span className="text-xs xl:text-base">
                {CASE_CREATION_EMAIL}
              </span>
              <CopyToClipBoardText
                label="COPY EMAIL ID"
                className="flex items-center gap-2 text-white font-semibold bg-custom-blue-census rounded-full p-2 cursor-pointer xl:text-sm"
              >
                {CASE_CREATION_EMAIL}
              </CopyToClipBoardText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Census;
