import axiosInstance from "./axiosInstance";

export const fetchCaseStudy = ({ params }) => {
  return axiosInstance.get(`/main/case-study`, {
    params,
  });
};

export const subcribeToMailingList = ({
  email,
  mailingListType,
  mailingListIdentifier,
}) => {
  return axiosInstance.post(`/main/mailing-list`, {
    email,
    mailingListType,
    mailingListIdentifier,
  });
};

export const subcribeToJan2024MailingList = ({ email }) => {
  return subcribeToMailingList({
    email,
    mailingListType: "PROMOTION",
    mailingListIdentifier: "JAN2024",
  });
};

export const checkFileStatus = (caseId) => {
  return axiosInstance.get(
    `${process.env.REACT_APP_API_BASE_URL_DEV}/main/case?caseId=${caseId}`,
  );
};

export const triggerCaseWorkflow = (formData) => {
  return axiosInstance.post(
    `${process.env.REACT_APP_API_BASE_URL_DEV}/main/case`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const fetchCaseDetails = (caseId, reportType = "census", fileName) => {
  return axiosInstance.get(
    `${process.env.REACT_APP_API_BASE_URL_DEV}/main/case`,
    {
      params: {
        caseId,
        reportType,
        fileName,
      },
    },
  );
};
