import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const useRevealOnScroll = ({ time }) => {
  const [revealed, setRevealed] = useState(false);

  useEffect(() => {
    // Delay the initial reveal
    const delay = time;
    const timer = setTimeout(() => {
      setRevealed(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [time]);
  return { revealed };
};

const useRevealOnScrollWhenInView = ({ time }) => {
  const [revealed, setRevealed] = useState(false);
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    // Delay the initial reveal
    if (inView) {
      const delay = time;
      const timer = setTimeout(() => {
        setRevealed(true);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [time, inView]);

  return { revealed, ref };
};

export { useRevealOnScrollWhenInView };

export default useRevealOnScroll;
