import React from "react";

const Notfound = () => {
  return (
    <div className="flex flex-col items-center justify-center  h-screen w-screen border-2">
      <span className="text-2xl">Page not found!</span>
      <span className="text-gray-600 text-xs">(Please check your URL)</span>
    </div>
  );
};

export default Notfound;
