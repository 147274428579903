import React, { FC } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, registerables } from "chart.js";
import { RemoveNull, ReportData } from "../../../../types";
import { pageColors } from "../../../../utils/colorUtils";

ChartJS.register(...registerables);

const Tenure: FC<{ data: RemoveNull<ReportData["census"]>["tenure"] }> = ({
  data,
}) => {
  if (Object.keys(data).length === 0) {
    return (
      <div className="w-full bg-blue-200/10 rounded-md flex-grow flex justify-center items-center my-2">
        No data available
      </div>
    );
  }
  return (
    <div className="w-full h-64 py-2 md:px-4 flex justify-center md:justify-start items-center">
      <Bar
        data={{
          labels: Object.keys(data),
          datasets: [
            {
              data: Object.values(data),
              backgroundColor: pageColors["primary"],
              barThickness: 20,
            },
          ],
        }}
        options={{
          layout: {
            padding: {
              top: 30,
            },
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "top",
              formatter: (value) => {
                return value;
              },
            },
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default Tenure;
