export const offersConfig = [
  {
    id: 1,
    imgSrc: "offer-img-1.svg",
    altText: "Low code/no code platform icon",
    description: "Low code/no code platform to build your customer journey",
  },
  {
    id: 2,
    imgSrc: "offer-img-2.svg",
    altText: "Integration icon",
    description:
      "Integration with BenAdmin and enrollment platforms including decision files",
  },
  {
    id: 3,
    imgSrc: "offer-img-3.svg",
    altText: "groups icon",
    description: "Turnkey solution to add new groups/products",
  },
  {
    id: 4,
    imgSrc: "offer-img-4.svg",
    altText: "analytics icon",
    description: "Behavioral analytics with actionable insights",
  },
  {
    id: 5,
    imgSrc: "offer-img-5.svg",
    altText: "journey icon",
    description: "Self configuring customer journey",
  },
  {
    id: 6,
    imgSrc: "offer-img-6.svg",
    altText: "emails icon",
    description:
      "Configurable trigger based emails for key moments of the customer journey",
  },
];
