import React, { FC, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { RemoveNull, ReportData } from "../../../../types";
import { pageColors } from "../../../../utils/colorUtils";

const GenderRatio: FC<{
  data: RemoveNull<ReportData["census"]>["genderRatio"];
}> = ({ data }) => {
  if (Object.keys(data).length === 0) {
    return (
      <div className="w-full bg-blue-200/10 rounded-md flex-grow flex justify-center items-center">
        No data available
      </div>
    );
  }

  return (
    <div className="w-full h-44 px-4 flex justify-center">
      <Doughnut
        data={{
          labels: [
            `Male (${data.male.percentage}%)`,
            `Female (${data.female.percentage}%)`,
            `Unknown (${data.unknown.percentage}%)`,
          ],
          datasets: [
            {
              data: [
                Math.round(+data.male.value),
                Math.round(+data.female.value),
                Math.round(+data.unknown.value),
              ],
              backgroundColor: [
                pageColors["300"],
                pageColors["primary"],
                pageColors["100"],
              ],
              borderWidth: 0.5,
            },
          ],
        }}
        options={{
          plugins: {
            datalabels: {
              color: "black",
              anchor: "center",
              align: "center",
              font: {
                weight: "bold",
              },
              formatter: (value, _) => {
                return value;
              },
            },
            legend: {
              display: true,
              position: "right",
            },
          },
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};

export default GenderRatio;
