import React from "react";
import HeroFull from "../components/sections/HeroFull";
import PlatformOffers from "../components/sections/PlatformOffers";
import JoinUsCareers from "../components/sections/JoinUsCareers";
import Statistics from "../components/sections/Statistics";
import IntegrationsMain from "../components/sections/IntegrationsMain";

function Home() {
  return (
    <>
      <HeroFull />
      <Statistics />
      <PlatformOffers />
      <IntegrationsMain />
      <JoinUsCareers />
    </>
  );
}

export default Home;
