import React, { useEffect, useState } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { IoIosAnalytics } from "react-icons/io";
import { BsClipboardData } from "react-icons/bs";
import { VscFiles } from "react-icons/vsc";
import { VscServerProcess } from "react-icons/vsc";
import ProcessingStep from "./ProcessingStep";
import { fileStatus } from "../../../constants";

const ProcessingStepSeparatingLine = ({ show }: { show: boolean }) => {
  return (
    <div
      className={`${show ? "border-t-gray-600" : "border-t-gray-400"} mt-6 sm:mt-7 w-5 sm:w-12 md:w-24 h-0 border-t-2 border-dashed`}
    ></div>
  );
};

const FileProcessingStatus = ({ status }: { status: string | null }) => {
  const [fileProcessingData, setFileProcessingData] = useState({
    analysingFile: false,
    extractingFile: false,
    deduplicatingFile: false,
    postProcessingFile: false,
    completedProcessing: false,
  });

  useEffect(() => {
    switch (status) {
      case fileStatus.CLASSIFIED:
        setFileProcessingData({
          ...fileProcessingData,
          analysingFile: true,
        });
        break;
      case fileStatus.PREPROCESSING:
        setFileProcessingData({
          ...fileProcessingData,
          analysingFile: true,
          extractingFile: true,
        });
        break;
      case fileStatus.DEDUPLICATING:
        setFileProcessingData({
          ...fileProcessingData,
          analysingFile: true,
          extractingFile: true,
          deduplicatingFile: true,
        });
        break;
      case fileStatus.POSTPROCESSING:
        setFileProcessingData({
          ...fileProcessingData,
          analysingFile: true,
          extractingFile: true,
          deduplicatingFile: true,
          postProcessingFile: true,
        });
        break;
      case fileStatus.COMPLETED:
        setFileProcessingData({
          analysingFile: true,
          extractingFile: true,
          deduplicatingFile: true,
          postProcessingFile: true,
          completedProcessing: true,
        });
        break;
    }
  }, [status]);

  return (
    <>
      {status !== "error" && (
        <div className="flex items-start my-6 gap-0 justify-center">
          <ProcessingStep
            text={"Analysing"}
            isDisabled={!fileProcessingData.analysingFile}
            value={fileStatus.CLASSIFIED}
            status={status}
          >
            <div className="p-3 sm:p-4">
              <IoIosAnalytics className="size-6 sm:size-8 md:size-8" />
            </div>
          </ProcessingStep>
          <ProcessingStepSeparatingLine
            show={fileProcessingData.extractingFile}
          />
          <ProcessingStep
            text={"Extracting"}
            isDisabled={!fileProcessingData.extractingFile}
            value={fileStatus.PREPROCESSING}
            status={status}
          >
            <div className="p-3 sm:p-4">
              <BsClipboardData className="size-5 sm:size-8 md:size-8" />
            </div>
          </ProcessingStep>
          <ProcessingStepSeparatingLine
            show={fileProcessingData.deduplicatingFile}
          />
          <ProcessingStep
            text={"Deduplicating"}
            isDisabled={!fileProcessingData.deduplicatingFile}
            value={fileStatus.DEDUPLICATING}
            status={status}
          >
            <div className="p-3 sm:p-4">
              <VscFiles className="size-5 sm:size-8 md:size-8" />
            </div>
          </ProcessingStep>
          <ProcessingStepSeparatingLine
            show={fileProcessingData.postProcessingFile}
          />
          <ProcessingStep
            text={"Post Processing"}
            isDisabled={!fileProcessingData.postProcessingFile}
            value={fileStatus.POSTPROCESSING}
            status={status}
          >
            <div className="p-3 sm:p-4">
              <VscServerProcess className="size-5 sm:size-8 md:size-8" />
            </div>
          </ProcessingStep>
          <ProcessingStepSeparatingLine
            show={fileProcessingData.completedProcessing}
          />
          <ProcessingStep
            text={"Completed"}
            isDisabled={!fileProcessingData.completedProcessing}
            value={fileStatus.COMPLETED}
            status={status}
          >
            <div className="p-3 sm:p-4">
              <AiOutlineFileDone className="size-6 sm:size-8 md:size-8" />
            </div>
          </ProcessingStep>
        </div>
      )}

      {status === "error" && (
        <div className="px-10 py-5 bg-red-200 rounded-xl">
          <p className="font-semibold text-red-700">
            There was an error while processing your census file.
          </p>
        </div>
      )}
    </>
  );
};

export default FileProcessingStatus;
