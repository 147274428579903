import { TiTick } from "react-icons/ti";
import React from "react";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { FaRegCopy } from "react-icons/fa";

const CopyToClipBoardText = ({
  children,
  label,
  className,
}: {
  children: string;
  label?: string;
  className?: string;
}) => {
  const { copied, handleCopyClick } = useCopyToClipboard();

  return (
    <button className={className} onClick={() => handleCopyClick(children)}>
      <span className="hover:text-custom-blue-census">
        {copied ? <TiTick /> : <FaRegCopy size={16} />}
      </span>
      <span className="hidden md:block">{label ?? children}</span>
    </button>
  );
};

export default CopyToClipBoardText;
