import React, { FC, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, registerables } from "chart.js";
import { RemoveNull, ReportData } from "../../../../types";
import { pageColors } from "../../../../utils/colorUtils";

ChartJS.register(...registerables);

const SalaryBand: FC<{
  data: RemoveNull<ReportData["census"]>["salaryBand"];
}> = ({ data }) => {
  const labels = useMemo(
    () =>
      Object.keys(data).map((key) =>
        key !== "NaN" ? (parseInt(key) / 1000).toString() : key,
      ),
    [data],
  );

  if (Object.keys(data).length === 0) {
    return (
      <div className="w-full bg-blue-200/10 rounded-md flex-grow flex justify-center items-center">
        No data available
      </div>
    );
  }

  return (
    <div className="w-full h-64 px-4 py-2 flex justify-center items-center">
      <Bar
        data={{
          datasets: [
            {
              data: Object.values(data),
              backgroundColor: pageColors["primary"],
              barThickness: Object.values(data).length > 20 ? 10 : 20,
            },
          ],
          labels,
        }}
        options={{
          layout: {
            padding: {
              top: 30,
            },
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "top",
              formatter: (value) => {
                return value;
              },
            },
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 45,
              },
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default SalaryBand;
