import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RingLoader } from "react-spinners";
import useRevealOnScroll from "../../hooks/useRevealOnScroll";
import tickMark from "../../assets/images/tickmark.svg";
import { subcribeToJan2024MailingList } from "../../services";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email")
    .required("Email is required"),
});

const Register = () => {
  const [error, setError] = useState(false);
  const { revealed } = useRevealOnScroll({ time: 600 });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async ({ email }) => {
    try {
      await subcribeToJan2024MailingList({ email });
    } catch (err) {
      setError(true);
      console.error(err);
      alert("Unable to subscribe to Uniblox. Please reload and try again.");
    }
  };
  return (
    <div className="my-5 md:mt-32 md:mb-60 relative py-5">
      <div className="h-[200px] w-[200px] md:h-[300px] md:w-[300px] lg:h-[400px] lg:w-[400px] absolute border border-red-50 fill-[#D04BFF] bg-[#D04BFF] lg:left-1/4 -translate-x-1/2 opacity-25 blur-3xl -z-10 rounded-full"></div>
      <div className="h-[200px] w-[200px] md:h-[300px] md:w-[300px] lg:h-[400px] lg:w-[400px] absolute border border-red-50 fill-[#4B7DFF] bg-[#4B7DFF] -right-[40px] lg:-right-[0px] translate-y-1/2 opacity-25 blur-3xl rounded-full -z-10"></div>
      <div
        className={`transition-custom duration-custom in-expo w-4/5 mx-auto mb-0 md:mb-16 ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <h1 className="text-4xl md:text-6xl lg:text-7xl font-black text-white font-poppins text-center mb-8 mt-6 leading-tight">
          Thank you for attending the webinar today
        </h1>
        <p className="text-center text-sm md:text-xl text-white font-poppins justify-start font-400 w-11/12 mx-auto leading-10 opacity-80 mb-10 md:mb-20 max-w-2xl">
          Please fill in your email below to receive updates and a special
          promotion from Uniblox
        </p>
        {!isSubmitSuccessful ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-fit m-auto flex flex-col md:flex-row justify-center gap-7 items-center md:items-start"
          >
            <div>
              <input
                type="email"
                className="focus:outline-none focus:border-[#482BD9] focus:ring-1 focus:ring-[#41D6CD] w-64 md:w-96 p-2 pl-5 text-base bg-white placeholder:text-base rounded-3xl"
                {...register("email")}
                placeholder="Enter your email address"
                disabled={isSubmitting}
              />
              {errors.email && (
                <p className="errormsg text-sm text-red-500 text-left mt-2 pl-5">
                  Please enter correct email address
                </p>
              )}
            </div>
            <button
              type="submit"
              className={`h-10 border border-neutral-100 rounded-lg grid items-center font-400 text-white opacity-95 px-8 text-xs md:text-base bg-custom-button-color hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet`}
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting ? (
                <RingLoader color="#fff" loading={isSubmitting} size={30} />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        ) : (
          <div className="text-center text-xs md:text-lg text-white font-poppins flex flex-col items-center gap-2">
            {!error && <img src={tickMark} />}
            <div>
              {error ? (
                <div className="text-red-500">{`Unable to subscribe to Uniblox. Please reload and try again.`}</div>
              ) : (
                `Thank you for subscribing! Get ready for exclusive Uniblox offers just for you!`
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
